<template>
  <b-container fluid v-if="vouchers != null">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-primary card-outline">
            <div class="card-header">
              <h2 class="card-title">Gutscheine / Coupons</h2>
              <div class="card-tools">
                  <nav class="py-0 bg-white navbar navbar-expand navbar-light justify-content-end">
                    <ul class="navbar-nav">  
                      <li class="nav-item mr-1">
                          <div class="input-group mt-0">
                            <input type="text" class="form-control" placeholder="Suchen" @keyup.enter="getVouchers" v-model="search"/>
                            <button type="submit" class="btn btn-default" @click.prevent="getVouchers">
                                <i class="fas fa-search"></i>
                            </button>
                            <button v-if="this.search != null" type="button" class="ml-1 btn btn-danger" @click="resetSearch">
                                <i class="fas fa-times"></i>
                            </button>
                          </div>
                      </li>
                      <li>
                        <div class="input-group mt-0">
                          <button type="button" class="mr-1 btn btn-default" @click="getVouchers">
                            <i class="fas fa-sync"></i>
                          </button>
                        </div>
                      </li>
                      <li>
                        <div class="input-group mt-0">
                          <button type="button" class="btn btn-primary" @click="createModal" v-if="$auth.check('vouchers.create')">
                            <i class="fas fa-plus-circle"></i> Gutschein / Coupon
                          </button>
                        </div>
                      </li>
                    </ul>
                </nav>
              </div>
            </div>
            <div class="card-header">
              <table class="table table-striped table-bordered" v-if="vouchers.data.length >= 1">
                <thead>
                  <th>Erstellt</th>
                  <th>Titel</th>
                  <th>Wert</th>
                  <th>Restwert</th>
                  <th>Code</th>
                  <th>Gültig ab</th>
                  <th>Gültig bis</th>
                  <th>Mindestbestellwert</th>
                  <th>Versandfrei</th>
                  <th>Aktiv</th>
                  <th>Aktion</th>
                </thead>
                <tbody>
                  <tr v-for="data in vouchers.data" :key="data.id">
                    <td>{{ data.created_at }}</td>
                    <td>{{ data.title }}</td>
                    <td v-if="data.percental == 0">{{ data.amount | toCurrency }}</td>
                    <td v-if="data.percental == 1">{{ data.amount / 100 | toPercent }}</td>
                    <td>
                      <template v-if="data.type == 'voucher'">{{ data.rest_amount | toCurrency }}</template>
                      <template v-if="data.type == 'coupon'"> - </template>  
                    </td>
                    <td>{{ data.code }}</td>
                    <td>{{ data.valid_from | formatDate }}</td>
                    <td>{{ data.valid_to | formatDate }}</td>
                    <td>{{ data.minimum_value | toCurrency }}</td>
                    <td>
                      <i v-if="data.shippingfree == 1" class="fas fa-check"></i>
                      <i v-if="data.shippingfree == 0" class="fas fa-times"></i>
                    </td>
                    <td>
                      <i v-if="data.active == 1" class="fas fa-check"></i>
                      <i v-if="data.active == 0" class="fas fa-times"></i>
                    </td>        
                    <td>
                        <button v-if="$auth.check('vouchers.download')" type="button" class="mr-1 btn btn-xs btn-secondary" :disabled="data.type == 'coupon'" @click="download(data)"><i class="fas fa-fw fa-download"></i></button>
                        <button v-if="$auth.check('vouchers.edit')" type="button" class="mr-1 btn btn-xs btn-warning" @click="editModal(data)" ><i class="fas fa-fw fa-edit"></i></button>
                        <button v-if="$auth.check('vouchers.destroy')" type="button" class="mr-1 btn btn-xs btn-danger" @click="deleteVoucher(data.id)"><i class="fas fa-fw fa-trash"></i></button>
                        <button v-if="$auth.check('vouchers.cash')" type="button" class="mr-1 btn btn-xs btn-info" @click="cashVoucher(data)" :disabled="data.type == 'coupon'"><i class="far fa-fw fa-id-card"></i></button>
                        <button v-if="$auth.check('vouchers.show')" type="button" class="mr-1 btn btn-xs btn-success" @click="openUsagesModal(data)" v-b-popover.hover.top="'Benutzungen'"><i class="fas fa-chart-area"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <span v-else><h5>Derzeit sind keine Gutscheine im System gespeichert.</h5></span>
            </div>
              <div class="clearfix align-middle card-footer">
                <pagination v-if="notEmptyObject(vouchers)" class="float-left" :data="vouchers" @pagination-change-page="getVouchers" :limit="3"></pagination>
                <select class="float-right form-control-sm" v-model="params.per_page">
                    <option value="25">25 Gutscheine</option>
                    <option value="50">50 Gutscheine</option>
                    <option value="75">75 Gutscheine</option>
                    <option value="100">100 Gutscheine</option>
                    <option value="125">125 Gutscheine</option>
                    <option value="150">150 Gutscheine</option>
                </select>
                <p v-if="notEmptyObject(vouchers)" class="float-right mr-2">Anzeige Eintrag {{ vouchers.meta.from }} - {{ vouchers.meta.to }} von {{ vouchers.meta.total }}</p>
            </div>
          </div>        
        </div>
      </div>
      <b-modal id="voucherModal" :title="modalTitle" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk($event, form.id)">
        <fieldset class="p-2 mb-2 border">
          <legend class="w-auto">Allgemein</legend>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="company_id" class="control-label">Firma*</label>
                <select class="form-control form-control-sm" v-model="form.company_id" :class="{'is-invalid': form.errors.has('company_id')}" @change="companyChanged" :disabled="editMode == true">
                  <option v-for="company in companies" :key="company.id" :value="company.id">{{ company.name }}</option>
                </select>
                <has-error :form="form" field="company_id"></has-error>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="type" class="control-label">Typ*</label>
                <select class="form-control form-control-sm" v-model="form.type" :class="{'is-invalid': form.errors.has('type')}" :disabled="editMode == true">
                  <option value="voucher">Gutschein</option>
                  <option value="coupon">Coupon</option> 
                </select>
                <has-error :form="form" field="type"></has-error>
              </div>
            </div>
            <div class="col-md-4" v-if="form.type == 'coupon'" >
              <div class="form-group">
                <label for="type" class="control-label">Modus*</label>
                <select class="form-control form-control-sm" v-model="form.mode" :class="{'is-invalid': form.errors.has('mode')}">
                  <option value="percent">Prozentual</option>
                  <option value="absolute">Absolut</option> 
                  <option value="shippingfree">Versandfrei</option> 
                </select>
                <has-error :form="form" field="mode"></has-error>
              </div>
            </div>
            <div class="col-md-4" v-if="this.form.type == 'voucher'">
                <label for="motiv" class="control-label">Motiv*</label>
                <select class="form-control form-control-sm" v-model="form.motiv" :class="{'is-invalid': form.errors.has('motiv')}" :disabled="form.type=='voucher' && editMode == true">
                  <option :value="null">-- Bitte wählen --</option>
                  <option v-for="template in templates" :key="template.id" :value="template.id">{{ template.title }}</option>
                </select>
                <has-error :form="form" field="motiv"></has-error>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
                <label for="title" class="control-label">Name*</label>
                <input type="text" class="form-control form-control-sm" v-model="form.title" :class="{'is-invalid': form.errors.has('title')}" :disabled="form.type=='voucher' && editMode == true">
                <has-error :form="form" field="title"></has-error>
            </div>

            <div class="col-md-4">
                <label for="value" class="control-label">Wert*</label>
                <input type="number" class="form-control form-control-sm" v-model="form.amount" :class="{'is-invalid': form.errors.has('amount')}" :disabled="form.type=='voucher' && editMode == true || form.mode=='shippingfree'">
                <has-error :form="form" field="amount"></has-error>
            </div>

            <div class="col-md-4" v-if="this.form.type == 'coupon'">
                <label for="code" class="control-label">Code</label>
                <input type="text" class="form-control form-control-sm" v-model="form.code" :class="{'is-invalid': form.errors.has('code')}">
                <has-error :form="form" field="code"></has-error>
            </div>
          </div>
        </fieldset>
        <fieldset class="p-2 border">
          <legend class="w-auto">Beschränkungen</legend>
          <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="active" class="control-label">Aktiv*</label>
                  <select class="form-control form-control-sm" v-model="form.active" :class="{'is-invalid': form.errors.has('active')}" >
                    <option value="1">Ja</option>
                    <option value="0">Nein</option> 
                  </select>
                  <has-error :form="form" field="active"></has-error>
                </div>
              </div>

              <div class="col-md-4">
                  <label for="number_of_units" class="control-label">Anzahl der Gutscheine</label>
                  <input type="number" class="form-control form-control-sm" v-model="form.number_of_units" :class="{'is-invalid': form.errors.has('number_of_units')}" :disabled="form.type=='voucher' && editMode == true">
                  <has-error :form="form" field="number_of_units"></has-error>
              </div>
              <div class="col-md-4" v-if="this.form.type == 'coupon'">
                  <label for="units_per_customer" class="control-label">Einlösbare Gutscheine je Kunde</label>
                  <input type="number" class="form-control form-control-sm" v-model="form.units_per_customer" :class="{'is-invalid': form.errors.has('units_per_customer')}">
                  <has-error :form="form" field="units_per_customer"></has-error>
              </div>
          </div>
          <div class="row">
            <div class="col-md-2">
                <label for="minimum_value" class="control-label">Mindestbestellwert</label>
                <input type="number" class="form-control form-control-sm" v-model="form.minimum_value" :class="{'is-invalid': form.errors.has('minimum_value')}" :disabled="form.type=='voucher' && editMode == true">
                <has-error :form="form" field="minimum_value"></has-error>
            </div>
            <div class="col-md-2">
                <label for="max" class="control-label">Maximalbestellwert</label>
                <input type="number" class="form-control form-control-sm" v-model="form.max" :class="{'is-invalid': form.errors.has('max')}" :disabled="form.type!='coupon' || form.mode == 'shippingfree'">
                <has-error :form="form" field="max"></has-error>
            </div>
            <div class="col-md-4">
                <label for="valid_from" class="control-label">Gültig von</label>
                <input type="date" class="form-control form-control-sm" v-model="form.valid_from" :class="{'is-invalid': form.errors.has('valid_from')}">
                <has-error :form="form" field="valid_from"></has-error>
            </div>

            <div class="col-md-4">
                <label for="valid_to" class="control-label">Gültig bis</label>
                <input type="date" class="form-control form-control-sm" v-model="form.valid_to" :class="{'is-invalid': form.errors.has('valid_to')}">
                <has-error :form="form" field="valid_to"></has-error>
            </div>
          </div>
        </fieldset>
      </b-modal>
      <b-modal id="voucherCashModal" title="Gutschein einlösen" ok-only ok-variant="danger" ok-title="Einlösen" size="sm" @ok="handleCashOk()">
          <div class="form-group">
            <label for="amount" class="control-label">Wert*</label>
            <input type="number" class="form-control form-control-sm" v-model="cashForm.amount" :class="{'is-invalid': cashForm.errors.has('amount')}" />
            <has-error :form="cashForm" field="amount"></has-error>
          </div>
          <div class="form-group">
            <input type="hidden" class="form-control form-control-sm" v-model="cashForm.voucher_id" :class="{'is-invalid': cashForm.errors.has('voucher_id')}" />
            <has-error :form="cashForm" field="voucher_id"></has-error>
          </div>
      </b-modal>


      <b-modal id="vouchersUsages" title="Gutschein Einlösungen" ok-only ok-variant="danger" size="xl" hide-footer>
        <div v-if="voucherUsages != null">
          <div class="row">
            <div class="col-md-4">
              <h3>Eingelöst: {{ voucherUsages.orders.length }}</h3>
            </div>
            <div class="col">
              
            </div>
            <div class="col-md-4">
  
            </div>
          </div>
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>Bestellung</th>
                <th>Wann</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in voucherUsages.orders" :key="data.id">
                <td>{{ data.ordernumber }}</td>
                <td>{{ data.orderdate }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-modal>
  </b-container>
</template>

<script>
export default {
  name: "VouchersIndex",
  title: "Gutscheine & Coupons",

  data(){
    return {
      vouchers: null,
      companies: [],
      templates: [],
      editMode: false,
      modalTitle: "",
      cashForm: new window.Form({
          voucher_id: '',
          amount: 0,
      }),
      form: new window.Form({
          id: '',
          company_id: '',
          type: 'voucher',
          mode: 'percent',
          amount: 0,
          title: 'Hofladen Gutschein',
          code: null,
          minimum_value: null,
          valid_from: null,
          valid_to: null,
          number_of_units: 1,
          units_per_customer: 1,
          active: 1,
          max: null,
          motiv: null,
      }),
      voucherUsages: null,
      page: 1,
      search: null,
      params: {
        per_page: 25,
      }
    }
  },
  watch: {
    params: {
      handler() {
        this.getVouchers();
      },
      deep: true,
    }
  },
  methods:{
    resetSearch(){
      this.search = null;
      this.getVouchers();
    },
    handleCashOk(){
      this.cashForm
            .post("/vouchers/cash")
            .then(() => {
                this.$bvModal.hide("voucherCashModal");
                this.getVouchers();
                this.$swal({
                    icon: "success",
                    title: "Gutscheine wurde eingelöst",
                });
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufennnnn",
                });
            });
    },

    cashVoucher(voucher){
        this.cashForm.clear();
        this.cashForm.reset();
        this.cashForm.fill({
          voucher_id: voucher.id,
          amount: voucher.rest_amount,
        });
        this.$bvModal.show("voucherCashModal");
    },

    deleteVoucher(id){
      this.$swal({
        title: "Möchtest du den Gutschein wirklich löschen?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `Löschen`,
        denyButtonText: `Abbrechen`,
      })
      .then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          //Send request to the Server
          this.form
            .delete("/vouchers/" + id)
            .then(() => {
              this.$swal({
                icon: "success",
                title: "Gutschein erfolgreich gelöscht!",
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
              });
              this.getVouchers();
            })
            .catch(() => {
              this.$swal({
                icon: "error",
                title: "Oops...",
                text: "Da ist etwas schief gelaufen",
              });
            });
        }
      });
    },

    download(data){
      this.axios
      .post("/vouchers/download", {
          'voucher_id': data.id,
      },{responseType: 'arraybuffer'})
      .then((response) => {
          this.downloadFile(response, data);
      })
      .catch(() => {
          this.$swal({
              icon: "error",
              title: "Oops...",
              text: "Da ist etwas schief gelaufen",
          });
          this.$Progress.fail();
      });
    },

    downloadFile(response, item){
      var newBlob = new Blob([response.data], {type: 'application/pdf'})

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob)
        return
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob)
      var link = document.createElement('a')
      link.href = data
      link.download = 'Gutschein-' + item.title + '-' + item.code + '.pdf';
      link.click()
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data)
      }, 100)
    },

    companyChanged(event){
      const result = this.companies.find( company => company.id == event.target.value);
      this.templates = result.vouchers.filter(element => element.active == 1);
    },
    
    handleOk(bvModalEvt, voucher) {
      bvModalEvt.preventDefault()
      this.handleSubmit(voucher)
    },

    handleSubmit(voucher) {
          
        if(this.editMode == true)
        {
            //Edit User
            this.editVoucher(voucher); 
        }
        else
        {
            //Create User
            this.createVoucher();
        }  
    },

    openUsagesModal(voucher) {
      this.$bvModal.show("vouchersUsages");
      this.voucherUsages = voucher;
    },

      editModal(Voucher) {
          this.editMode = true;
          this.modalTitle = "Gutschein editieren";
          this.form.clear();
          this.form.reset();
          // this.form.company_id = this.companies[0].id;
          this.templates = this.companies[0].vouchers;
          this.form.fill(Voucher);
          this.$bvModal.show("voucherModal");

      },

    createModal() {
        this.editMode = false;
        this.modalTitle = "Neue Gutschein anlegen";
        this.form.clear();
        this.form.reset();
        this.form.company_id = this.companies[0].id;
        this.templates = this.companies[0].vouchers;
        this.$bvModal.show("voucherModal");
    },


    createVoucher(){
        this.$Progress.start();
        this.form
            .post("/vouchers")
            .then(() => {
              this.getVouchers();
              this.$bvModal.hide("voucherModal");
              this.$swal({
                  icon: "success",
                  title: "Gutscheine wurde hinzugefügt",
              });
              this.$Progress.finish();
            })
            .catch(() => {
              this.$Progress.fail();
            });
    },

    editVoucher(id){
        this.$Progress.start();
        this.form
            .put("/vouchers/" + id)
            .then(() => {
              this.getVouchers();
              this.$bvModal.hide("voucherModal");
              this.$swal({
                  icon: "success",
                  title: "Änderungen wurden gespeichert",
              });
              this.$Progress.finish();
            })
            .catch(() => {
              this.$Progress.fail();
            });
    },

    getVouchers(page)
    {
      this.$Progress.start();
      if(page == undefined)
      {
          page = this.page 
      }
      this.axios
          .get("/vouchers", {
              params: {
                page:page,
                search: this.search,
                ...this.params
              }
            })
          .then((response) => {
              this.vouchers = response.data;
              this.$Progress.finish();
          })
          .catch(() => {
              this.$swal({
                  icon: "error",
                  title: "Oops...",
                  text: "Da ist etwas schief gelaufen",
              });
              this.$Progress.fail();
          });
    },

    getCompanies()
    {
      this.$Progress.start();
      this.axios
          .get("/companies")
          .then((response) => {
              this.companies = response.data.data;
              this.$Progress.finish();
          })
          .catch(() => {
              this.$swal({
                  icon: "error",
                  title: "Oops...",
                  text: "Da ist etwas schief gelaufen",
              });
              this.$Progress.fail();
          });
    }
  },

  created(){
    this.getVouchers();
    this.getCompanies();
  }
}
</script>

<style>

</style>